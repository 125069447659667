import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"

import { Episode } from "../types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"

interface Props {
  data: {
    contentfulPodcast: Episode
  }
}

const EpisodeTemplate: FunctionComponent<Props> = ({ data }) => {
  const [activePodcastId, setActivePodcastId] = useState("")
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [seekPosition, setSeekPosition] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState("00:00:00")

  const playerRef = useRef<HTMLAudioElement | null>(null)

  const formatTime = useCallback((time: number) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - hours * 3600) / 60)
    const seconds = Math.floor(time - hours * 3600 - minutes * 60)

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`
  }, [])

  useEffect(() => {
    function onSeek(event: Event) {
      const target = event.target as HTMLAudioElement

      setSeekPosition((target.currentTime / target.duration) * 100)
      setTimeElapsed(formatTime(target.currentTime))
    }

    const player = playerRef.current

    if (player) {
      player.addEventListener("timeupdate", onSeek)
    }

    return () => {
      if (player) {
        player.removeEventListener("timeupdate", onSeek)
      }
    }
  }, [formatTime])

  function onPlayClick() {
    if (playerRef.current) {
      setIsPlaying(!isPlaying)

      if (isPlaying) {
        playerRef.current.pause()
        setActivePodcastId("")
      } else {
        playerRef.current.play()
        setActivePodcastId(data.contentfulPodcast.id)
      }
    }
  }

  return (
    <Layout>
      <SEO
        description={
          data.contentfulPodcast.blurb == ""
            ? data.contentfulPodcast.title
            : data.contentfulPodcast.blurb
        }
        title={data.contentfulPodcast.title}
        link={
          data.contentfulPodcast.slug
            ? `https://mooremomentum.com/podcast/${data.contentfulPodcast.slug}/`
            : `https://mooremomentum.com/podcast/${data.contentfulPodcast.episodeNumber}/`
        }
        image={data.contentfulPodcast.coverImage?.url}
      />
      <div className="max-w-6xl mt-8 mx-auto px-4 overflow-x-hidden">
        <h1 className="font-Orbitron mb-4 text-center text-2xl sm:text-3xl lg:text-4xl">
          {data.contentfulPodcast.title}
        </h1>
        <h2 className="text-center mb-32">
          {data.contentfulPodcast.publishedDate}
        </h2>{" "}
        <div className="blog max-w-6xl mt-8 mx-auto px-4 blog_text">
          <OnScreen
            className="px-4 text-center md:text-left"
            classToAdd="slide-in-right"
          >
            <div
              className="bg-blue max-w-6xl mx-auto -mt-24 p-6 relative rounded-2xl shadow-xl z-20 flex"
              style={{ minHeight: "380px" }}
            >
              <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:gap-8">
                <div>
                  {data.contentfulPodcast?.video ? (
                    <iframe
                      src={data.contentfulPodcast?.video}
                      title="YouTube video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      width={"100%"}
                      height={"100%"}
                    ></iframe>
                  ) : (
                    <GatsbyImage
                      alt={data.contentfulPodcast.coverImage.description}
                      className="mb-6 w-50"
                      image={data.contentfulPodcast.coverImage.gatsbyImageData}
                    />
                  )}
                </div>

                <div className="self-center text-white">
                  <div className="mb-8 text-center">
                    <div className="border border-white inline-block p-1 rounded-full">
                      <button
                        className="bg-white flex items-center justify-center h-16 rounded-full transition-opacity w-16 hover:opacity-70"
                        type="button"
                        onClick={onPlayClick}
                      >
                        <FontAwesomeIcon
                          className="text-yellow text-2xl"
                          icon={isPlaying ? faPause : faPlay}
                        />
                      </button>
                    </div>
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  {data.contentfulPodcast.mediaUrl && (
                    <>
                      <audio
                        controls
                        ref={playerRef}
                        onCanPlay={() =>
                          setDuration(playerRef.current?.duration || 0)
                        }
                        className="mb-3 relative w-full"
                      >
                        <source
                          src={data.contentfulPodcast.mediaUrl}
                          type="audio/mpeg"
                        />
                      </audio>

                      {/* <div className="mb-4">
                        <div className="bg-gray h-1 mb-3 relative w-full">
                          <div
                            className="absolute bg-yellow h-1 left-0"
                            style={{ width: `${seekPosition}%` }}
                          />
                        </div>

                        <div className="flex justify-between">
                          <p className="text-sm">{timeElapsed}</p>

                          <p className="text-sm">{formatTime(duration)}</p>
                        </div>
                      </div> */}

                      <p className="mb-4 mt-8 text-sm">
                        {!data.contentfulPodcast.blurb
                          ? "No Description"
                          : data.contentfulPodcast.blurb}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </OnScreen>
        </div>
        <div className="py-8 space-y-4 blog">
          {/* @ts-ignore */}
          {renderRichText(data.contentfulPodcast.content, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
            },
          })}
          {/* <Button href="https://anchor.fm/5corelife/message" link="GATSBY">
            Send Voice Message @Anchor
          </Button> */}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPodcast(slug: { eq: $slug }) {
      content {
        raw
      }
      coverImage {
        description
        url
        gatsbyImageData(layout: CONSTRAINED)
      }
      publishedDate(formatString: "MMM D, YYYY")
      slug
      title
      episodeNumber
      id
      blurb
      mediaUrl
      video
    }
  }
`

export default EpisodeTemplate
